import React from 'react';

import { ColorTokenProp } from '../../../theme/tokens/color';

import { ParagraphWithLineBreaks } from '../styles';

import { NodeProps } from './shared';

interface ParagraphNodeProps extends NodeProps {
  colour?: ColorTokenProp | string;
  plansTierStyling?: boolean;
}

export function ParagraphNode({ colour, children, plansTierStyling = false }: ParagraphNodeProps) {
  const desktopFontSize = plansTierStyling ? 'sm': 'p_desktop';
  return (
    <ParagraphWithLineBreaks
      as="p"
      fontSize={{ 0: 'p_0', desktop: desktopFontSize }}
      colour={colour}
      removeBottomMarginSpacing={plansTierStyling}
    >
      {children}
    </ParagraphWithLineBreaks>
  );
}
