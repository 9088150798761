import React from 'react';
// import OptInBanner from './OptInBanner';
// import OptOutBanner from './OptOutBanner';

export const getCookieBannerComponent = (
  lat: number,
  lng: number
): React.ReactNode => {
  //   Define Quebec boundaries
  const isInQuebec = lat >= 45 && lat <= 62 && lng >= -79.5 && lng <= -57;

  //   Define general Canada boundaries
  const isInCanada = lat >= 41 && lat <= 83 && lng >= -141 && lng <= -52.6;

  if (isInQuebec) {
    return <div />;
  } else if (isInCanada) {
    return <span />;
  } else {
    return null; // Outside Canada, no banner needed
  }
};
