import React from 'react';
import { Block, BLOCKS, Inline } from '@contentful/rich-text-types';

import { usePageDataContext } from '../../../../context/PageData';

import RichText from '../../../RichText';
import { Text } from '../../../Text';

export function Disclaimers() {
  const { config: { footerDisclaimer } = {} } = usePageDataContext();

  return (
    <RichText
      data={footerDisclaimer}
      overrideOptions={{
        [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => (
          <Text
            as="p"
            fontSize={{ 0: '2xs', tablet: 's' }}
            colour="footerTextSecondary"
            fontWeight="400"
            marginTop="sm"
          >
            {children}
          </Text>
        )
      }}
    />
  );
}
