import React, { useEffect, ReactNode } from 'react';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { IPageFields } from '@/types/contentful';

import { usePageAnalytics } from '../../hooks/usePageAnalytics';
import { getCookie } from '../../utils/cookies';
import analytics from '../../utils/analytics';

import Meta from './Meta';
import { Headers } from './Headers';
import { LeadCapture } from '../LeadCapture';
import { Footers } from './Footers';
import { Callouts } from './Callouts';

import { Wrapper } from './styles';
import { Educator } from '../../context/User';
import { ExitIntentContainer } from './ExitIntentContainer';
import { usePageDataContext } from '../../context/PageData';
import CookieBanner from '../CookieBanner/CookieBanner';

interface BodyProps extends IPageFields {
  children?: ReactNode | ReactNode[];
  query?: NextParsedUrlQuery;
  pathPrefix?: string;
  bgColor?: string;
  user?: Educator;
}

export function Body({
  children,
  query,
  pathPrefix,
  bgColor,
  analyticsMetadata,
  hideFooterCta,
  user,
  exitIntent,
  cookieBanner,
  ...page
}: BodyProps) {
  const { articleConfig: { regCTAToggle } = {} } = usePageDataContext();

  usePageAnalytics({
    analyticsMetadata,
    query,
    pathPrefix,
    slug: page?.slug,
    user
  });

  useEffect(() => {
    const extoleSharableCode = getCookie('extole_shareable_code');
    const advocatePartnerShareId = getCookie('advocate_partner_share_id');
    const referralCampaign = getCookie('referral_campaign');
    const referralRewardValue = getCookie('referral_reward_value');
    if (extoleSharableCode || advocatePartnerShareId) {
      analytics.track(
        'Referred User App Opened',
        omitBy(
          {
            extole_shareable_code: `${extoleSharableCode}` || null,
            advocate_partner_share_id: `${advocatePartnerShareId}` || null,
            campaign_name: `${referralCampaign}` || null,
            reward_value: `${referralRewardValue}` || null
          },
          isNil
        )
      );
    }
  }, []);

  // Override hideFooterCta if regCTAToggle exists
  const shouldHideFooterCta =
    regCTAToggle !== undefined ? regCTAToggle : hideFooterCta;

  return (
    <Wrapper bgColor={bgColor ?? '#FFFFFF'}>
      <Meta {...page} pathPrefix={pathPrefix} />
      {cookieBanner && <CookieBanner />}

      <Headers {...page} />

      <LeadCapture />
      <main>
        {children}

        {!shouldHideFooterCta && <Callouts {...page} />}
      </main>

      {exitIntent && <ExitIntentContainer {...exitIntent} />}

      <Footers {...page} />
    </Wrapper>
  );
}
