import React, { useState, useEffect, SyntheticEvent, useMemo } from 'react';

import { ILink, ILinkFields } from '@/types/contentful';

import { usePageDataContext } from '../../../../context/PageData';
import { useCookie } from '../../../../hooks/useCookie';

import {
  readOnetrustSettings,
  CONSENT_COOKIE_KEY,
  CONSENT_OPT_IN,
  CONSENT_OPT_OUT
} from '../../../../utils/analytics/onetrust';
import { trackWithFingerprint } from '../../../../utils/trackWithFingerprint';

import { Flexbox, FlexboxProps } from '../../../Flexbox';
import { ConsentModal } from '../../../ConsentModal';

import {
  FooterLegalNavItemList,
  SecondaryNavItem,
  SecondaryLink
} from './styles';

interface LegalNavLinksProps extends FlexboxProps {
  isAffiliateFooter?: boolean;
  links?: ILink[];
}

export function LegalNavLinks({
  isAffiliateFooter,
  links,
  ...props
}: LegalNavLinksProps) {
  const { cookies: { [CONSENT_COOKIE_KEY]: pvcyCookie } = {} } =
    usePageDataContext();

  // Handle consent cookie default from server
  const pvcySettings = useMemo(
    () => readOnetrustSettings(pvcyCookie),
    [pvcyCookie]
  );

  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [optedOut, setOptedOut] = useState<boolean>(
    pvcySettings?.C0004 === false
  );

  const [pCookie, updateCookie] = useCookie(CONSENT_COOKIE_KEY);

  useEffect(() => {
    // Update consent cookie when toggle changes
    const settings = readOnetrustSettings(pCookie);
    setOptedOut(settings?.C0004 === false);
  }, [pCookie]);

  const updatePrivacyStatus = () => {
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 12);

    const pvcy = !optedOut ? CONSENT_OPT_IN : CONSENT_OPT_OUT;

    updateCookie(pvcy, { expires: expiryDate });
  };

  const toggleOptOutModal = () => {
    if (isConsentModalOpen === false) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = '';
    }

    // Update privacy cookie and toggle modal
    updatePrivacyStatus();
    setIsConsentModalOpen(!isConsentModalOpen);
  };

  return (
    <Flexbox {...props} data-testid="legal-nav-list">
      <FooterLegalNavItemList>
        {links &&
          links.map(({ sys, fields }) => {
            const { title, url, openInNewTab, hasAffiliateLink }: ILinkFields =
              fields;
            const path = url ?? '';

            return title?.toLowerCase().includes('do not sell') ? (
              <SecondaryNavItem
                data-testid="privacy-modal-link"
                key={sys?.id || title}
              >
                <SecondaryLink
                  href="#"
                  onClick={(e: SyntheticEvent) => {
                    e.preventDefault();
                    trackWithFingerprint('Do Not Sell Selected', {
                      opted_out: optedOut
                    });
                    toggleOptOutModal();
                  }}
                >
                  {title}
                </SecondaryLink>
              </SecondaryNavItem>
            ) : (
              <SecondaryNavItem key={sys?.id || title}>
                <SecondaryLink
                  href={
                    isAffiliateFooter && hasAffiliateLink
                      ? `/affiliates${path}`
                      : path
                  }
                  target={openInNewTab ? '_blank' : ''}
                  rel="noopener noreferrer"
                >
                  {title}
                </SecondaryLink>
              </SecondaryNavItem>
            );
          })}
      </FooterLegalNavItemList>
      {isConsentModalOpen && (
        <ConsentModal
          optedOut={optedOut}
          setOptedOut={setOptedOut}
          toggleOptOutModal={toggleOptOutModal}
        />
      )}
    </Flexbox>
  );
}
