import React, { useEffect, useState } from 'react';
import { getCookieBannerComponent } from './bannerUtils';

const CookieBanner = () => {
  const [bannerComponent, setBannerComponent] = useState<React.ReactNode>(null);

  useEffect(() => {
    const requestBrowserGeolocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            // Get the appropriate banner component based on location
            const banner = getCookieBannerComponent(latitude, longitude);
            setBannerComponent(banner);
          },
          () => {
            // User declined geolocation or an error occurred, default to opt-in banner
            setBannerComponent(null);
          }
        );
      } else {
        // Geolocation not supported, default to opt-in banner
        setBannerComponent(null);
      }
    };

    requestBrowserGeolocation();
  }, []);

  return <div>{bannerComponent}</div>;
};

export default CookieBanner;
