import React from 'react';

import { IFooter20Fields } from '../../../../../types/contentful';

import { Disclaimers } from './Disclaimers';
import { LegalNavLinks } from './LegalNavLinks';
import { PageFootnotes } from './PageFootnotes';

import { BrightEdgeColumn } from './styles';

export function Legal({
  legalLinks,
  pageFootnotes,
  isAffiliateFooter
}: IFooter20Fields) {
  return (
    <div
      className="mb-[92px] bg-[#000] px-5 py-10 tablet:px-10 desktop:mb-0 desktop:mt-0 desktop:px-15"
      data-blockid="legal-footer20"
      data-testid="legal-footer20"
    >
      <BrightEdgeColumn size={12}>
        {/* BrightEdge Autopilot */}
        <div className="be-ix-link-block" />
      </BrightEdgeColumn>
      <div id="legal-layout" className="flex flex-col gap-5">
        <LegalNavLinks
          links={legalLinks}
          isAffiliateFooter={isAffiliateFooter}
        />
        <div className="gap-4">
          {pageFootnotes && <PageFootnotes {...pageFootnotes} />}
          <Disclaimers />
        </div>
      </div>
    </div>
  );
}
